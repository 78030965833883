.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #5b5b5b;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 18px;
}

input {
  float: right;
  font-size: large;
  border-radius: 4px;
  color: #555;
  padding-left: 5px;
}

td input {
  padding-left: 5px;
  width: 100%;
}

button {
  float: left;
  background: #e0683a;
  border: #de440999 1px solid;
  color: white;
  font-size: 20px !important;
  padding: 7px;
}

a {
  padding: 10px;
  cursor: pointer;
}

label {
  color: #555;
}

.formInputs input {
  font-size: x-large;
  width: 100%;
  float: none !important;
  margin-top: 5px;
}

th {
  text-align: left;
  padding-left: 10px;
  color: #555;
}

td {
  vertical-align: right !important;
  padding-left: 10px;
  color: #555;
}

.imagePreview {
  border: black 1px solid;
  border-radius: 6px;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
